<template>
  <div class="data-smart-cockpit">
    <div class="wrap">
      <div class="title">
        <h1>商业管理数智化时代</h1>
        <p>以IT和DT为驱动，通过整合内外部数据实现购物中心数字化经营水平的提升</p>
      </div>
      <div class="mainpanel fl">
        <div class="common-box data-screen">
          <h2>数据大屏</h2>
          <ul>
            <li
              class="hover-shadow"
              @click="entryPage(0)"
            >
              <h3>项目经营大屏</h3>
              <p>项目数据全局可视，助力运营决策</p>
              <span>立即查看</span>
              <div class="bg-1"></div>
            </li>
            <!-- <li
              class="hover-shadow"
              @click="entryPage(1)"
            >
              <h3>品牌分析大屏</h3>
              <p>洞悉签约品牌，赋能品牌管理</p>
              <span>立即查看</span>
              <div class="bg-2"></div>
            </li> -->
          </ul>
        </div>
        <div class="common-box mt-16 analysis-warning">
          <h2>分析预警</h2>
          <ul>
            <li
              class="item-list-box hover-shadow pointer"
              @click="entryPage(2)"
            >
              <h3 class="w-title-gradients">室内地图</h3>
              <div class="item-list-item"></div>
              <h4>店铺可视化分析</h4>
              <p>多维剖析，可视化呈现项目及商铺经营数据<br>
                深度挖掘商铺的经营情况，协助项目经营策略调控</p>
              <el-button
                v-show="analysisWarningShowBtn[0]"
                class="btnPrimary btnCommon"
              >立即查看</el-button>
            </li>
            <li
              class="item-list-box hover-shadow pointer"
              @click="entryPage(3)"
            >
              <h3 class="w-title-gradients">高度自定义</h3>
              <div class="item-list-item"></div>
              <h4>项目预警监控</h4>
              <p>自定义预警管理，监控项目关键数据指标<br>
                如租售比、合同、坪效、欠费等，赋能项目管理</p>
              <el-button
                v-show="analysisWarningShowBtn[1]"
                class="btnPrimary btnCommon"
              >立即查看</el-button>
            </li>
            <li
              class="item-list-box hover-shadow pointer"
              @click="entryPage(4)"
            >
              <h3 class="w-title-gradients">内外数据整合</h3>
              <div class="item-list-item"></div>
              <h4>店铺预警分析</h4>
              <p>整合内外部数据，多维度分析签约商户<br>
                从竞品、客流、坪效、销售情况等深度剖析商户经营</p>
              <el-button
                v-show="analysisWarningShowBtn[2]"
                class="btnPrimary btnCommon"
              >立即查看</el-button>
            </li>
          </ul>
        </div>
        <div class="common-box mt-16 business-billboard">
          <h2>业务看板</h2>
          <ul>
            <li
              class="hover-shadow"
              @click="entryPage(5)"
            >
              <h3>招商看板</h3>
              <p>可视化呈现项目招商数据，协助招商管理，把控招商进度</p>
              <span><i class="icon-Enter"></i>前往查看</span>
            </li>
            <li
              class="hover-shadow"
              @click="entryPage(6)"
            >
              <h3>租务看板</h3>
              <p>可视化呈现项目租务数据，宏观把控租售情况</p>
              <span><i class="icon-Enter"></i>前往查看</span>
            </li>
            <li
              class="hover-shadow"
              @click="entryPage(7)"
            >
              <h3>营运看板</h3>
              <p>可视化呈现项目运营数据，把控项目销售、客流及费用收缴情况</p>
              <span><i class="icon-Enter"></i>前往查看</span>
            </li>
            <li
              class="hover-shadow"
              @click="entryPage(8)"
            >
              <h3>财务看板</h3>
              <p>可视化呈现项目财务数据，剖析项目资金情况</p>
              <span><i class="icon-Enter"></i>前往查看</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="sidebar common-box fr">
        <h2>市场研报</h2>
        <ul>
          <li
            v-for="(item,i) in marketSurveyList"
            :key="i"
            @click="toMarketReserch(i)"
          >
            <span v-html="item.text1"></span>
            <span v-html="item.text2"></span>
          </li>
        </ul>
      </div>
      <div class="clearfix"></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import api from '@/api'
export default {
  name: 'dataSmartCockpit',
  data () {
    return {
      marketSurveyList: [
        {
          text1: '2021年中国购物中心行业年度发展报告',
          text2: ''
        },
        {
          text1: '2022年Q2热搜品牌榜分析报告<br>',
          text2: ''
        },
        {
          text1: '数智商业的快与慢<br>',
          text2: ''
        },
        {
          text1: '麦肯锡2020年<br>',
          text2: '中国消费者调查报告'
        }
      ],
      tooltipFlag: false,
      analysisWarningShowBtn: [0, 0, 0],
      urlList: [],
      rightsList: [],
      hasRightShow: false,
      canLookMarketReserch: []
    }
  },
  computed: {
    ...mapGetters({
      token: 'token',
      user: 'user'
    })
  },
  mounted () {
    // 店铺预警权限
    const userAuthoritiesShow = this.user.confIds.find(item => {
      return item.id === 172
    })
    if (userAuthoritiesShow) {
      if (userAuthoritiesShow.func.indexOf('show') !== -1) {
        this.hasRightShow = true
      }
    } else {
      this.hasRightShow = false
    }
    // 市场研报查看权限
    this.canLookMarketReserch = this.user.confIds.filter(item => {
      return item.id === 168
    })
    this.getUrlList()
    // 分析预警 图像动效
    const _this = this
    const imgHeightList = [920, 855, 969]
    const boxHeightList = [115, 114, 114]
    for (let k = 0; k < 3; k++) {
      const o = document.getElementsByClassName('item-list-box')[k]
      let n = 0
      let t = 0
      o.onmouseover = function () {
        clearInterval(n)
        n = setInterval(function () {
          if (t < 20 && boxHeightList[k] * t < imgHeightList[k]) {
            t++
            document.getElementsByClassName('item-list-item')[k].style.backgroundPositionY = -boxHeightList[k] * t + 'px'
          } else {
            clearInterval(n)
          }
        }, 20)
        _this.$set(_this.analysisWarningShowBtn, k, 1)
      }
      o.onmouseout = function () {
        clearInterval(n)
        n = setInterval(function () {
          if (t > 0 && boxHeightList[k] * t > 0) {
            t--
            document.getElementsByClassName('item-list-item')[k].style.backgroundPositionY = -boxHeightList[k] * t + 'px'
          } else {
            clearInterval(n)
          }
        }, 20)
        _this.$set(_this.analysisWarningShowBtn, k, 0)
      }
    }
  },
  methods: {
    handleTooltip (key, e) {
      this.$set(this.analysisWarningShowBtn, 2, key)
      this.tooltipFlag = !!key
    },
    getUrlList () {
      this.axios.post(api.getDPurl).then(res => {
        if (res.data.data) {
          const resData = res.data.data
          for (let i = 0; i < resData.length; i++) {
            const item = resData[i]
            this.rightsList.push(item.qtSort)
            switch (item.qtSort) {
              case 100: // 项目经营大屏
                this.urlList[0] = item
                break
              case 101: // 品牌分析大屏
                this.urlList[1] = item
                break
              case 1003: // 店铺可视化分析
                this.urlList[2] = item
                break
              case 1004: // 预警监控看板
                this.urlList[3] = item
                break
              case 1005: // 商户画像分析
                this.urlList[4] = item
                break
              case 1207: // 招商看板
                this.urlList[5] = item
                break
              case 1208: // 租务看板
                this.urlList[6] = item
                break
              case 1209: // 营运看板
                this.urlList[7] = item
                break
              case 1210: // 财务看板
                this.urlList[8] = item
                break
              default:
                break
            }
          }
        }
      })
    },
    entryPage (key) {
      const qtSortList = [100, 101, 1003, 1004, 1005, 1207, 1208, 1209, 1210]
      if (this.rightsList.indexOf(qtSortList[key]) !== -1) {
        if (qtSortList[key] === 101) {
          if (this.isGxjt) {
            const url = this.urlList[key].url.replace(/wbms2|wbms1|wbms/g, 'gxjt')
            window.open(url + '?&token=' + this.token, '_blank')
          } else {
            window.open(this.urlList[key].url + '?&token=' + this.token, '_blank')
          }
        } else if (key === 3) {
          window.open('/#/early?id=' + this.urlList[key].id, '_blank')
        } else if (key === 4) {
          if (this.hasRightShow) {
            const path = this.$router.resolve({
              path: '/storeEarlywarning'
            })
            window.open(path.href, '_blank')
          } else {
            this.$message({
              type: 'warning',
              message: '抱歉，暂无权限访问此应用模块！'
            })
          }
        } else {
          if (key === 0) {
            window.open(this.$winshangDomain + '#/projectBigScreen?id=' + this.urlList[key].id + '&tokenval=' + this.token, '_blank')
          } else if (key === 2) {
            window.open(this.$winshangDomain + '#/visualStoreAnalysis', '_blank')
          } else if (key === 5) {
            window.open(this.$winshangDomain + '#/business?id=' + this.urlList[key].id + '&tokenval=' + this.token, '_blank')
          } else if (key === 6) {
            window.open(this.$winshangDomain + '/gdwscrm/BI/rent?id=' + this.urlList[key].id + '&tokenval=' + this.token, '_blank')
            // http://prewbms.winshangdata.com/gdwscrm/BI/rent?id=134&tokenval=2ACEB03FF1B05E98DDBAEE87D29292FE.20220122172424
          } else if (key === 7) {
            window.open(this.$winshangDomain + '/gdwscrm/BI/operating?id=' + this.urlList[key].id + '&tokenval=' + this.token, '_blank')
          } else if (key === 8) {
            window.open(this.$winshangDomain + '/gdwscrm/BI/financial?id=' + this.urlList[key].id + '&tokenval=' + this.token, '_blank')
          } else {
            window.open(this.$winshangDomain + '?id=' + this.urlList[key].id + '&tokenval=' + this.token, '_blank')
          }
          // window.open(this.urlList[key].url + '?id=' + this.urlList[key].id + '&tokenval=' + this.token, '_blank')
        }
      } else {
        this.$message({
          type: 'warning',
          message: '抱歉，暂无权限访问此应用模块！'
        })
      }
    },
    toMarketReserch (index) {
      if (this.canLookMarketReserch.length > 0) {
        const pdfUrl1 = 'https://jscss.winshangdata.com/pdf/Report01.pdf'
        const pdfUrl2 = 'https://jscss.winshangdata.com/pdf/Report02.pdf'
        const pdfUrl3 = 'https://jscss.winshangdata.com/pdf/Report03.pdf'
        const pdfUrl4 = 'https://jscss.winshangdata.com/pdf/Report04.pdf'
        switch (index) {
          case 0:
            if (pdfUrl1) {
              window.open(pdfUrl1, '_blank')
              // window.open('http://' + window.location.host + '/#/marketReserch?pdfUrl=' + pdfUrl1, '_blank') // 这个是PDFJS，打开有点慢，但是兼容性好
            } else {
              this.$message({
                type: 'warning',
                message: '暂无数据'
              })
            }
            break
          case 1:
            if (pdfUrl2) {
              window.open(pdfUrl2, '_blank')
              // window.open('http://' + window.location.host + '/#/marketReserch?pdfUrl=' + pdfUrl2, '_blank')
            } else {
              this.$message({
                type: 'warning',
                message: '暂无数据'
              })
            }
            break
          case 2:
            if (pdfUrl3) {
              window.open(pdfUrl3, '_blank')
              // window.open('http://' + window.location.host + '/#/marketReserch?pdfUrl=' + pdfUrl3, '_blank')
            } else {
              this.$message({
                type: 'warning',
                message: '暂无数据'
              })
            }
            break
          case 3:
            if (pdfUrl4) {
              window.open(pdfUrl4, '_blank')
              // window.open('http://' + window.location.host + '/#/marketReserch?pdfUrl=' + pdfUrl4, '_blank')
            } else {
              this.$message({
                type: 'warning',
                message: '暂无数据'
              })
            }
            break
        }
      } else {
        this.$message({
          type: 'warning',
          message: '抱歉，暂无权限访问此应用模块！'
        })
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.data-smart-cockpit
  background url('~@/assets/images/dataSmartCockpit/bg.png') top right no-repeat
  background-size 1920px 154px
  overflow hidden
  min-height 500px
  .mt-16
    margin-top 16px
  a
    color #fff
  .wrap
    width 1792px
    margin 0 auto
    color #fff
    .title
      margin 47px 0
      h1
        font-size 28px
        line-height 28px
        overflow hidden
        margin-bottom 16px
        font-weight 600
      p
        line-height 18px
        font-weight 400
        font-size 18px
    .common-box
      width 100%
      background #272930
      border-radius 3px
      overflow hidden
      padding 22px 0 0 35px
      box-sizing border-box
      h2
        height 18px
        line-height 16px
        font-size 18px
        text-indent 13px
        border-left 4px solid #F89C34
    .mainpanel
      width 1334px
      .data-screen
        height 288px
        ul
          li
            width 96%
            height 190px
            display inline-block
            margin-top 27px
            cursor pointer
            transition all 0.2s
            position relative
            .bg-1
              background url('~@/assets/images/dataSmartCockpit/dataScreen1.png') top no-repeat
            .bg-2
              background url('~@/assets/images/dataSmartCockpit/dataScreen2.png') top no-repeat
            .bg-1, .bg-2
              width 100%
              height 100%
              position absolute
              top 0
              left 0
              transition all 0.4s
              background-size cover
            &:first-child
              margin-right 32px
            &:hover .bg-1, &:hover .bg-2
              transform scale(1.1)
            h3, p, span
              position absolute
              z-index 1
              left 40px
            h3
              width 120px
              margin 0 !important
              top 58px
              padding-bottom 9px
              font-size 18px
              border-bottom 2px solid #F89C34
            p
              top 103px
              font-size 14px
            span
              top 134px
              font-size 14px
              color #F89C34
      .shadow-box
        box-shadow 0px 5px 51px 0px rgba(18, 18, 20, 0.3)
        overflow hidden
        &:hover
          box-shadow 0px 5px 51px 0px rgba(18, 18, 20, 0.52)
      .hover-shadow
        transition all 0.4s
        &:hover
          box-shadow 0px 5px 51px 0px rgba(18, 18, 20, 0.52)
      .analysis-warning
        ul
          margin 23px 0 27px 0
          li:last-child
            margin-right 0
          li
            width 400px
            height 331px
            display inline-block
            overflow hidden
            margin-right 32px
            border-radius 3px
            text-align center
            position relative
            h3
              text-align left
            h4
              font-size 18px
              margin 16px 0 19px 0
            p
              width 307px
              margin 0 auto 8px auto
              font-size 14px
              opacity 0.8
            .item-list-item
              width 99px
              height 114px
              margin 6px auto
              padding 0
              background-size 100%
              background-repeat no-repeat
            .btnCommon
              position absolute
              bottom 15px
              left 0
              margin-left calc(50% - 70px)
            &:first-child .item-list-item
              background url('~@/assets/images/dataSmartCockpit/analysisWarning1.png')
              height 115px
            &:nth-child(2) .item-list-item
              background url('~@/assets/images/dataSmartCockpit/analysisWarning2.png')
            &:last-child .item-list-item
              background url('~@/assets/images/dataSmartCockpit/analysisWarning3.png')
      .business-billboard
        ul
          margin 24px 0 27px 0
          li
            width 616px
            height 160px
            display inline-block
            padding 34px 0 0 31px
            box-sizing border-box
            cursor pointer
            background-position top right
            h3
              font-size 18px
              margin-bottom 8px
            p
              font-size 14px
              margin-bottom 11px
              width 250px
              opacity 0.8
            .icon-Enter:before
              margin-right 7px
            span
              font-size 14px
              opacity 0.8
            &:hover
              span, i
                color #F89C34
            &:first-child
              margin 0 32px 33px 0
              background-image url('~@/assets/images/dataSmartCockpit/businessBillboard1.png')
            &:nth-child(2)
              margin 0 0 33px 0
              background-image url('~@/assets/images/dataSmartCockpit/businessBillboard2.png')
            &:nth-child(3)
              margin 0 32px 0 0
              background-image url('~@/assets/images/dataSmartCockpit/businessBillboard3.png')
            &:last-child
              margin 0
              background-image url('~@/assets/images/dataSmartCockpit/businessBillboard4.png')
    .sidebar
      width 442px
      height 1197px
      padding 22px 0 0 24px
      ul
        margin-top 25px
        li:before
          content ''
          position absolute
          top 0
          left 0
          width 394px
          height 250px
          background-color rgba(0, 0, 0, 0.7)
          transition background-color 0.4s
        li:after
          transition opacity 0.4s
          content ''
          position absolute
          top 76px
          left 0
          width 395px
          height 98px
          background linear-gradient(-90deg, rgba(213, 225, 255, 0) 0%, rgba(213, 225, 255, 0.2) 46%, rgba(213, 225, 255, 0) 100%)
          box-shadow 0px 5px 51px 0px rgba(18, 18, 20, 0.52)
          border-radius 3px
        li
          position relative
          width 394px
          height 250px
          box-sizing border-box
          margin-bottom 33px
          cursor pointer
          overflow hidden
          &:first-child
            background url('~@/assets/images/dataSmartCockpit/marketSurvey1.png') top center no-repeat
          &:nth-child(2)
            background url('~@/assets/images/dataSmartCockpit/marketSurvey2.png') top center no-repeat
          &:nth-child(3)
            background url('~@/assets/images/dataSmartCockpit/marketSurvey3.png') top center no-repeat
          &:nth-child(4)
            background url('~@/assets/images/dataSmartCockpit/marketSurvey4.png') top center no-repeat
          &:hover
            &:before
              background-color rgba(0, 0, 0, 0)
            &:after
              opacity 0
            span
              &:first-child
                margin-left 1000px
              &:last-child
                margin-left -1000px
          span
            transition margin 0.4s
            display inline-block
            line-height 20px
            font-size 20px
            font-weight 500
            text-align center
            width 100%
            position relative
            z-index 2
            &:first-child
              margin 103px 0 5px 0
.w-title-gradients
  width 119px !important
  background linear-gradient(to right, rgba(255, 161, 52, 0.3), rgba(255, 255, 255, 0))
.btn-disabled
  background rgba(43, 48, 58, 0.5) !important
  color rgba(255, 255, 255, 0.05) !important
  border-color rgba(43, 48, 58, 0.5) !important
  &:active, &:hover
    background rgba(43, 48, 58, 0.5) !important
    color rgba(255, 255, 255, 0.05) !important
    border-color rgba(43, 48, 58, 0.5) !important
.btn-disabled>>>
  span:hover, &:focus span:hover
    color rgba(255, 255, 255, 0.05) !important
@media screen and (max-width 1920px)
  .data-smart-cockpit
    .wrap
      width 95%
      .mainpanel
        width 74%
        .data-screen
          ul
            li
              // width 47%
              height 190px
              overflow hidden
            h3
              margin 30px 0 10px 40px !important
      .analysis-warning
        ul
          li
            width 30.5% !important
            p
              width 95% !important
      .business-billboard
        ul
          li
            width 47.5% !important
    .sidebar
      width 25% !important
      ul
        margin-top 25px
@media screen and (max-width 1700px)
  .data-smart-cockpit
    .wrap
      .mainpanel
        .analysis-warning
          ul
            li
              width 30% !important
      .sidebar
        height 1167px
        ul
          li
            width 97%
            margin-bottom 23px !important
@media screen and (max-width 1465px)
  .data-smart-cockpit
    .wrap
      .mainpanel
        .data-screen
          height 266px
          li
            height 138px !important
            h3
              top 25px !important
            p
              top 75px !important
            span
              top 103px !important
      .sidebar
        height 1167px
        ul
          li
            margin-bottom 23px !important
</style>
